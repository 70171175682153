/** COOKIES MODAL **/

.cookiesModalOverlay {
  background: rgba(70, 82, 92, 0.3) !important;
}

.cookiesModal {
  vertical-align: bottom !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
  width: 100%;
  box-shadow: 0 1px 2px 0px #333 !important;
}
