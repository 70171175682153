.pointsList {
  position: absolute;
}

.pointsList-hidden-entered {
  z-index: 10;
}

.pointsList-hidden-entering {
  animation-name: points-list-in;
  animation-duration: 500ms;
  z-index: 10;
}

.pointsList-exited {
  opacity: 0;
  visibility: hidden;
}

.pointsList-on-screen-entered {
  /*overflow: hidden;*/
  z-index: 10;
}

.pointsList-screen-entering {
  z-index: 10;
}

.pointsList-exiting {
  z-index: 10;
}

.pointsList-exited {
  z-index: 10;
}

@keyframes points-list-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes points-list-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.collapse {
  position: sticky;
  top: 0;
}
