body {
  background-color: #0900ba;
}
/* index.css */
.app {
  min-height: calc(var(--vh, 1vh) * 100);
}

.scrolled-header {
  position: fixed;
  top: 0;
  width: 100%;
  animation-name: header-intro;
  animation-duration: 1000ms;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky-header-entering {
  position: fixed;
  top: 0;
  width: 100%;
  animation-name: sticky-header-in;
  animation-duration: 500ms;
}

.sticky-header-exiting {
  position: fixed;
  top: 0;
  width: 100%;
  animation-name: sticky-header-out;
  animation-duration: 500ms;
}

@keyframes shrink-header {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0px);
  }
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
}

.header-entering {
  position: fixed;
  top: 0;
  width: 100%;
  animation-name: header-in;
  animation-duration: 500ms;
}

.header-exiting {
  position: fixed;
  top: 0;
  width: 100%;
  animation-name: header-out;
  animation-duration: 500ms;
}

@keyframes header-in {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes header-out {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-200px);
  }
}

@keyframes sticky-header-in {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes sticky-header-out {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-100px);
  }
}

.headerIn {
  animation-name: header-out;
  animation-duration: 1000ms;
}

/* @keyframes header-intro {
  0% {
    height: auto;
  }

  100% {
    height: 80px;
  }
} */

.pointInfo {
  position: absolute;
}

.pointInfo-entering {
  animation-name: p-intro;
  animation-duration: 500ms;
  z-index: 10;
}

.pointInfo-exiting {
  animation-name: p-out;
  animation-duration: 500ms;
  height: 100vh;
}

.survey {
  position: absolute;
  z-index: 10;
}

.survey-entering {
  animation-name: p-intro;
  animation-duration: 500ms;
}

.survey-exiting {
  animation-name: p-out;
  animation-duration: 500ms;
}

.map {
  position: absolute;
}

.map-entering {
  z-index: 10;
  animation-name: p-intro;
  animation-duration: 500ms;
}
.map-entered {
  z-index: 10;
  animation-name: p-intro;
  animation-duration: 500ms;
}
.map-entered2 {
  z-index: 0;
  animation-name: p-intro;
  animation-duration: 500ms;
}

.map-exiting {
  z-index: 10;
  animation-name: p-out;
  animation-duration: 500ms;
}

.policy {
  position: absolute;
}

.policy-entering {
  animation-name: p-intro;
  animation-duration: 500ms;
}

.policy-exiting {
  animation-name: p-out;
  animation-duration: 500ms;
}

/* 
.pointInfo-entered {
  background-color: blueviolet;
} */

@keyframes p-intro {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes p-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

/* .pointInfo {
  position: absolute;
  overflow: hidden;
}

.pointInfo-entering {
  overflow: hidden;
  animation-name: transition-page;
  animation-duration: 500ms;
}

.pointInfo-exiting {
  animation-name: page-out;
  animation-duration: 500ms;
}

@keyframes transition-page {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes page-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
} */

body {
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* .pointInfo-exited {
  width: 0;
  height: 0;
  left: 110%;
  opacity: 0;
  visibility: hidden;
} */

.bigHotspot {
  animation: goBig 1s;
}

.smallHotspot {
  animation: goSmall 1s;
}

.goWhite {
  animation: goWhiteAnim 1s;
}

.goRed {
  animation: goRedAnim 1s;
}

@keyframes goWhiteAnim {
  0% {
    fill: #e4007d;
  }
  100% {
    fill: white;
  }
}

@keyframes goRedAnim {
  0% {
    fill: white;
  }
  100% {
    fill: #e4007d;
  }
}

@keyframes goBig {
  0% {
    transform: scale(0.06);
  }
  100% {
    transform: scale(0.08);
  }
}

@keyframes goSmall {
  0% {
    transform: scale(0.08);
  }
  100% {
    transform: scale(0.06);
  }
}

.linePressedButton {
  border-top: 3px solid white;
}

.lineButton {
  border-top: 3px solid #0900b9;
}

.encuestaButton {
  color: white;
  border: 2px solid white; /* Green */
  padding: 6px 7px 6px 7px;
}
.readMoreButton {
  color: white;
  border: 1px solid white; /* Green */
  padding: 6px 7px 6px 7px;
  background-color: #0900b9;
}

.spining {
  animation: spinAnimation 2s infinite linear;
}

@keyframes spinAnimation {
  100% {
    transform: rotate(360deg);
  }
}

.box {
  -webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
}

.boxHeader {
  -webkit-box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.3);
}

.pointNameBox {
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.footerBox {
  -webkit-box-shadow: 0px -20px 90px 25px rgba(6, 1, 129, 1);
  box-shadow: 0px -20px 90px 25px rgba(6, 1, 129, 1);
}

.footerBoxMap {
  -webkit-box-shadow: 0px 15px 90px 25px rgba(6, 1, 129, 1);
  box-shadow: 0px 15px 90px 25px rgba(6, 1, 129, 1);
}

.buttonBox {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
}

.imageFade {
  -webkit-mask-image: linear-gradient(
    0deg,
    rgba(6, 1, 129, 1) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  mask-image: linear-gradient(
    0deg,
    rgba(6, 1, 129, 1) 0%,
    rgba(255, 255, 255, 0) 50%
  );
}

.imageFadeScrolled {
  -webkit-mask-image: linear-gradient(
    0deg,
    rgba(6, 1, 129, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-image: linear-gradient(
    0deg,
    rgba(6, 1, 129, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.audioPlayerCircle {
  background-color: #e5007d;
  border: 3px solid white;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

@keyframes buttonClickedAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.buttonClicked {
  animation: buttonClickedAnim 0.2s linear;
}

.fade-out {
  animation: fadeOut ease 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-02 {
  animation: fadeIn02 ease 1s;
}

@keyframes fadeIn02 {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.fade-pulse {
  animation: fadePulse linear 0.8s;
}

@keyframes fadePulse {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
