.languagesSelector {
  position: absolute;
  top: 0;
  display: none;
  z-index: 110;
}

.languagesSelector-entering {
  animation-name: languages-in;
  animation-duration: 500ms;
  display: block;
}
.languagesSelector-entered {
  display: block;
}

.languagesSelector-exiting {
  animation-name: languages-out;
  animation-duration: 500ms;
  display: block;
}

.languagesSelector-exited {
  opacity: 0;
  transform: translateX(-100%);
}

@keyframes languages-in {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes languages-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
