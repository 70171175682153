.pointContainer {
  opacity: 1;

}

.pointContainer-entering {
  animation-name: point-intro;
  animation-duration: 500ms;
}

.pointContainer-exiting {
  transform: translate3d(-100%, 0, 0);
  animation-name: point-out;
  animation-duration: 500ms;
  width: 100%;
}

.pointContainer-exited {
  opacity: 0;
  transform: translateX(-100%);
}

.pointContainerOut-entering {
  animation-name: point-intro-left;
  animation-duration: 500ms;
}
.pointContainerOut-exiting {
  transform: translate3d(-100%, 0, 0);
  animation-name: point-out-left;
  animation-duration: 500ms;
  width: 100%;
}

.pointContainerOut-exited {
  opacity: 0;
  transform: translateX(-100%);
}

@keyframes point-intro {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes point-intro-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes point-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes point-out-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
